import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import flockLogo from '../images/logo.svg';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { LogoContainer } from '.';

const PrivacyPage = () => (
    <Layout>
        <SEO
            title="The app for fitness professionals"
            description="FLOCK.fitness is here to change how fitness professionals engage with their clients."
        />

        <LogoContainer>
            <Link to="/">
                <img src={flockLogo} />
            </Link>
        </LogoContainer>

        <h1>Privacy statement</h1>
        <h2>What data we need</h2>

        <p>The personal data we collect from you includes:</p>

        <ul>
            <li>
                questions, queries or feedback you leave, including your name
                and email address if you contact We Make Waves.
            </li>
            <li>
                your Internet Protocol (IP) address, and details of which
                version of web browser you used
            </li>
            <li>
                information on how you use the site, using cookies and page
                tagging techniques
            </li>
        </ul>

        <h2>Why we need it</h2>

        <p>
            We use Google Analytics software to collect information about how
            you use flock.fitness. We do this to help make sure the site is
            meeting the needs of its users and to help us make improvements, for
            example improving the user experience of the site.
        </p>

        <p>
            <strong>Google Analytics stores information about:</strong>
        </p>

        <ul>
            <li>the pages you visit on flock.fitness</li>
            <li>how long you spend on each flock.fitness page</li>
            <li>how you got to the site</li>
            <li>what you click on while you’re visiting the site</li>
        </ul>

        <p>
            We do not collect or store your personal information when browsing
            our site (for example your name or address) so this information
            cannot be used to identify who you are.
        </p>

        <h2>What we do with your data</h2>

        <p>
            The data we collect may be shared with supplier organisations only
            if necessary.
        </p>

        <p>We will not:</p>

        <ul>
            <li>sell or rent your data to third parties</li>
            <li>share your data with third parties for marketing purposes</li>
        </ul>

        <p>
            We will share your data if we are required to do so by law – for
            example, by court order, or to prevent fraud or other crime.
        </p>

        <h2>How long we keep your data</h2>

        <p>We will only retain your personal data for as long as:</p>

        <ul>
            <li>it is needed for the purposes set out in this document</li>
            <li>the law requires us to</li>
        </ul>

        <p>
            In general, this means that we will only hold your personal data for
            a minimum of 1 year and a maximum of 7 years.
        </p>

        <h2>Children’s privacy protection</h2>

        <p>
            Our services are not designed for, or intentionally targeted at,
            children 13 years of age or younger. We do not intentionally collect
            or maintain data about anyone under the age of 13.
        </p>

        <h2>How we protect your data and keep it secure</h2>

        <p>
            We are committed to doing all that we can to keep your data secure.
            We also make sure that any third parties that we deal with keep all
            personal data they process on our behalf secure.
        </p>

        <h2>Your rights</h2>

        <p>You have the right to request:</p>

        <ul>
            <li>information about how your personal data is processed</li>
            <li>a copy of that personal data</li>
            <li>
                that anything inaccurate in your personal data is corrected
                immediately
            </li>
        </ul>

        <p>You can also:</p>

        <ul>
            <li>
                request that your personal data is erased if there is no longer
                a justification for it
            </li>
            <li>
                ask that the processing of your personal data is restricted in
                certain circumstances
            </li>
        </ul>

        <p>
            If you have any of these requests, get in contact with us via{' '}
            <a href="mailto:james@flock.fitness">james@flock.fitness.</a>
        </p>

        <h2>Links to other websites</h2>

        <p>flock.fitness contains links to other websites.</p>

        <p>
            This privacy policy only applies to flock.fitness, and doesn’t cover
            other websites that we link to.
        </p>

        <p>
            <strong>Following a link to another website</strong>
        </p>

        <p>
            If you go to another website from this one, read the privacy policy
            on that website to find out what it does with your information.
        </p>

        <h2>Preventing cookies</h2>

        <p>
            If you wish to prevent the use of cookies on this (or any) site then
            you can manage these small files and learn more about them from the
            following Directgov article,{' '}
            <a href="http://www.direct.gov.uk/en/SiteInformation/Cookies/DG_WP201851?CID=Central&amp;PLA=url_mon&amp;CRE=managing_cookies">
                Internet Browser cookies – what they are and how to manage them
            </a>
        </p>
    </Layout>
);

export default PrivacyPage;
